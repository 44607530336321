<template>
  <div class="courses-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>教学内容管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="menu-list">
        <div class="menu-item" @click="enter('special')">
          专题管理
        </div>
        <div class="menu-item" @click="enter('classify')">
          专题分类
        </div>
        <div class="menu-item" @click="enter('min-classify')">
          小分类
        </div>
        <div class="menu-item" @click="enter('course')">
          课程管理
        </div>
        <div class="menu-item" @click="enter('subject')">
          课程项目
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Courses',
  methods: {
    enter (item) {
      this.$router.push('/admin/content/courses/' + item)
    }
  }
}
</script>

<style scoped>
.menu-list{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.menu-item{
  width: 250px;
  height: 150px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: url('../../assets/img/item-bg.jpeg');
  background-size: cover;
  color: #FFFFFF;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 4px;
  opacity: 0.7;
  -webkit-transition: all 0.1s ease-out 0.1s;
  -moz-transition: all 0.1s ease-out 0.1s;
  -o-transition: all 0.1s ease-out 0.1s;
  transition: all 0.1s ease-out 0.1s;
}
.menu-item:hover{
  box-shadow: rgba(103, 119, 239, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  opacity: 1;
  transform: scale(1.01);
}
</style>
